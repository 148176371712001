

































import { Component, Vue } from "vue-property-decorator";
import { ParticlesBg } from "particles-bg-vue";
import Typed from "typed.js"

@Component({
    components: {
        ParticlesBg
    }
})
export default class Introduction extends Vue {

    mounted() {
        new Typed('#typed', {
            loop: true,
            typeSpeed: 70,
            backSpeed: 30,
            backDelay: 1500,
            startDelay: 1500,
            smartBackspace: true,
            showCursor: true,
            cursorChar: '_',
            stringsElement: '#typed-strings'
        });
    }

    // Is mobile or not
    get mobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
    }

    get fullHeight(): string {
        if (this.$vuetify.breakpoint.smAndDown)
            return 'height: calc(100vh - 100px);'
        
        return 'height: 100vh;';
    }
}
