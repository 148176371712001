





































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SkillGroup extends Vue {
    @Prop({ required: true }) readonly text!: string;
    @Prop({ required: true }) readonly title!: string;
    @Prop({ required: true }) readonly subtitle!: string;
    @Prop({ required: true }) readonly icon!: string;
    @Prop({ required: true }) readonly tools!: Array<object>;

    // Is mobile or not
    get mobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
    }

    // Is theme dark active?
    get isThemeDark(): boolean {
		return this.$vuetify.theme.dark;
	}
}
