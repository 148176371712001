






















































import { MetaInfo } from "vue-meta";
import { Component, Vue } from "vue-property-decorator";
import Indtroduction from "@/components/Introduction.vue";
import Skills from "@/components/Skills.vue";
import Knowledge from "@/components/Knowledge.vue";
import About from "@/components/About.vue";

import firebase from "firebase/app";

@Component({
    metaInfo(): MetaInfo {
        firebase.analytics().logEvent("page_view");
        firebase.analytics().logEvent("screen_view", {
            'app_name': process.env.VUE_APP_PROJECT,
            'app_type': process.env.VUE_APP_TYPE,
            'screen_name': 'Home',
            'app_version': process.env.VUE_APP_VERSION
        });

        return {
            title: "Home",
        };
    },

    components: {
        Indtroduction,
        Skills,
        Knowledge,
        About
    }
})
export default class Home extends Vue {
    loading = true;

    mounted() {
        setTimeout(() => this.loading = false, 600);
    }

    // Download cv file
    downloadCV() {
        // Log
        this.$analytics.logEvent("download_cv");

        // Create element with pdf to download
        const link = document.createElement('a');

        link.href = process.env.VUE_APP_CV_FILE;
        link.download = `${process.env.VUE_APP_AUTHOR}`;
        link.click();

        // Delete node
        link.remove()
    }

    // Is mobile or not
    get mobile(): boolean {
        return this.$vuetify.breakpoint.smAndDown;
    }

    get dividerText(): string {
        if (this.$vuetify.breakpoint.lgAndUp)
            return 'welcome-text-lg';
        else if (this.$vuetify.breakpoint.mdOnly)
            return 'welcome-text-md';
        else
            return 'welcome-text-sm';
    }

    get dividerMargin(): string {
        if (this.$vuetify.breakpoint.smAndDown)
            return 'margin-top: -9rem;'
        
        return 'margin-top: -16rem;';
    }

    get dividerColor(): string {
        return `background-color: ${this.$vuetify.theme.currentTheme.accent};`;
    }
}
